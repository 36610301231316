.Footer-container{
    position: relative;
}

.Footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footerr{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links{
    display: flex;
    gap: 4rem;
}

.social-links>img{
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}

.logo-f>img{
    width: 10rem;
}

.blur-f{
    width: 26rem;
    height: 30rem;
    left: 35%
}